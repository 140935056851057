import React from "react";
import Main from "../components/main";
import SEO from "../components/section/seo";
import ESafetyComponent from "../components/esafety";

export default function MFA() {
  return (
    <Main >
      <SEO title="Dayman Apartments - eSafety" />
      <ESafetyComponent/>
    </Main>
  )
}