import { Link } from "gatsby";
import React from "react";

const TIPS = [
  {
    title: "Online Safety Guidelines for Kids, Teens, and Adults",
    content: <p>The internet is one of those incredible tools that kids and teens can use to advance their learning and education, to socialise and interact with friends, and to have some fun and be entertained. But as with any tool, it's important to know what makes it safe or unsafe, and how to protect yourself from harm.</p>
  },
  {
    title: "Internet safety tips for kids:",
    content: (
      <React.Fragment>
        <ul>
          <li>Supervise your children's use of the internet.</li>
          <li>Ensure connected devices, including mobile devices, are used in common areas such as loungerooms.</li>
          <li>Ensure online account names, e.g. gamer tags, are appropriate and can never be traced, including names, contact details and physical addresses.</li>
          <li>Educate yourself about online services and functionality, including social media such as Facebook, TikTok, Snapchat, messaging services and online forums.</li>
          <li>Support your children through regular discussions about appropriate and inappropriate internet usage, online experiences and questions.</li>
          <li>Encourage your children to report to you, a teacher, or other support person, about experiences with inappropriate online content and the behaviours of others online.</li>
          <li>Check browsing and search histories.</li>
          <li>Use internet filtering products and security software to block/restrict access to inappropriate content, for example, certain websites, video chats, forums or live streaming.</li>
        </ul>
      </React.Fragment>
    )
  },
  {
    title: "Internet safety tips for teens:",
    content: (
      <React.Fragment>
        <p>As your kids grow and enter their teens, the boundaries may move a little further out. They may use devices outside of the home common areas, they may purchase things online, they may spend more time on social media.</p>
        <ul>
          <li>Talk to your teens about the online threats you're concerned about so they can more easily identify them. This should include cyber bullying or harassment, contact from strangers, and phishing or identity theft scams.</li>
          <li>Give them ground rules. Some simple ground rules include: not sharing personal information online, not accepting a friend request from people they don’t know, not opening email from unknown senders, and not sharing photos online.</li>
          <li>Agree that you will be a follower or friend of all their social media accounts. This will give you the opportunity to learn more about each social media platform, so you can identify threats or hazards, and it will give you an opportunity to see what they're sharing online.</li>
          <li>Be on the lookout for secretive behaviour, like hiding or moving their screens when you enter the room, creating new accounts, or any big behavioural changes after time online.</li>
        </ul>
      </React.Fragment>
    )
  },
  {
    title: "Internet safety tips for adults:",
    content: (
      <React.Fragment>
        <p>e-Safety is just as important for adults as it is for kids and teens. Below are some tips for keeping your online accounts safe, including protecting your personal and financial information.</p>
        <ul>
          <li>Update your devices regularly to ensure you have the latest security in place</li>
          <li>Turn on multi-factor authentication to add another layer of protection to your accounts</li>
          <li>Back up your important files to safeguard your data from threats</li>
          <li>Use passphrases that use four or more random words and password managers to help generate or store secure and unique passphrases</li>
          <li>Don't reuse passwords or use the same password for multiple systems</li>
          <li>Always triple check the sender email address before opening attachments or clicking on links</li>
          <li>Don't reply to texts from numbers you don't know, especially when they claim to be a member of your family needing help</li>
        </ul>
        <div>For more information on keeping your family safe online, visit the <a href="https://www.esafety.gov.au/" target="_blank">Office of the eSafety Commissioner</a> website.</div>
      </React.Fragment>
    )
  }
]

const FILTERS = [
  {
    title: "What are filters?",
    content: <p>Online content filters are typically a piece of software, or similar tools or systems, that allows users to block or restrict access to websites and programs.</p>
  },
  {
    title: "What are the Family Friendly Filter programs?",
    content: (
      <React.Fragment>
        <p>CommAlliance's Family Filter Program provides a list of accredited filter providers that may be suitable for your family.</p>
        <p>For filter providers to specifically qualify for Family Friendly Filter status and be listed as an accredited provider, a filter must undergo rigorous independent testing to ensure that it meets criteria that are intended to protect families.</p>
      </React.Fragment>
    )
  },
  {
    title: "Classes of accredited Family Friendly Filters:",
    content: (
      <React.Fragment>
        <p>These filters block websites on the eSafety Commissioner's Prohibited URL Filter (PUF) list, and are recommended for 18+ years of age</p>
        <ul>
          <li>Class 1: Recommended for children over 15 years of age</li>
          <li>Class 2: Recommended for children between 10 and 15 years of age</li>
          <li>Class 3: Recommended for children under 10 years of age</li>
        </ul>
        <div>Further information, including a list of accredited filter providers, can be found on the <a href="https://www.commsalliance.com.au/Activities/ispi/fff" target="_blank">CommsAlliance Family Friendly Filter</a> website.</div>
      </React.Fragment>
    )
  }
]

const ESafety = () => {
  return (
    <div className="esafety-container">
      <Link to="/" className="back-home"><i className="fa fa-caret-left" /><span>Back to Home</span></Link>
      <div className="esafety-section">
        <h1>Online Safety</h1>
        <p>
          On this page you will find information on online safety, including information on how to protect kids and teens from inappropriate and harmful online content.
          This includes advice and information on services and tools, to protect from harmful online content.
        </p>
      </div>
      <div className="esafety-tips">
        {TIPS.map(tip => {
          return (
            <div className="esafety-section">
              <h3>{tip.title}</h3>
              {tip.content}
            </div>
          )
        })}
      </div>
      <div className="esafety-filters">
        <h2>Family Friendly Filters</h2>
        {FILTERS.map(filter => {
          return (
            <div className="esafety-section">
              <h3>{filter.title}</h3>
              {filter.content}
            </div>
          )
        })}
      </div>
      <div className="esafety-complaints">
        <h3>Complaints</h3>
        <p>Complaints relating to restricted or illegal content can be reported online to the <a href="https://www.esafety.gov.au/report/what-is-illegal-restricted-content" target="_blank">Office of the eSafety Commissioner</a> or to the content provider.</p>
        <p>If you are unable to submit your complaint online, and you are an Australian resident, you may be able to make a complaint by emailing online@esafety.gov.au. In your email, please provide specific URL information and the reasons why you believe the content should be prohibited.</p>
        <p>If you need to report any criminal activity, please go to Crime Stoppers online or call 1800 333 000. Reports can be anonymous.</p>
      </div>
      <div className="esafety-commissioner">
        <h3>eSafety Commissioner (eSafety)</h3>
        <p>eSafety's role is to help safeguard all Australians from online harms and to promote safer, more positive online experiences.</p>
        <p>eSafety uses research, evidence and experience to lead and advise on online safety issues.</p>
        <p>eSafety approaches its work through the three lenses of prevention, protection, and proactive and systemic change:</p>
        <ul>
          <li>prevention - through research, education programs and raising awareness</li>
          <li>protection - through regulatory schemes and investigations, under the Online Safety Act</li>
          <li>proactive and systemic change - by staying ahead of technology trends, supporting industry to improve user safety standards, and strengthening our impact across borders.</li>
        </ul>
        <div>For more information for staying safe online, Visit the <a href="https://www.esafety.gov.au/" target="_blank">Office of the eSafety Commissioner</a> website.</div>
      </div>
      <div className="esafety-final-word">
        <h3>A final word on how to stay safe online:</h3>
        <p>The internet is an incredible innovation - it's completely changed how we live, work, stay entertained, stay connected, get directions, even order food. But we do have to remember to be on the lookout for threats and hazards and exercise caution when interacting online, particularly for children.</p>
        <p>Have open conversations in your home about e-safety, about avoiding scams, about avoiding interactions with strangers - especially when they ask for money, personal information, or photos - and speaking up when you experience cyberbullying or intimidation.</p>
      </div>
    </div>
  )
}

export default ESafety
